import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {User} from 'firebase';
import {AngularFirestore} from '@angular/fire/firestore';
import {first} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import UserCredential = firebase.auth.UserCredential;

@Injectable()
export class AuthService {

  actualUser: User | null;
  userId: any;
  public userData;
  private userDataEmitter = new BehaviorSubject({});
  userDataObservable = this.userDataEmitter.asObservable();

  constructor(private _afa: AngularFireAuth, private router: Router, private _db: AngularFirestore, private http: HttpClient) {
    this.updateUserData();
  }

  get authState() {
    return this._afa.authState;
  }

  login(email, password): Promise<UserCredential> {
    return this._afa.auth.signInWithEmailAndPassword(email, password);
  }

  signUp(email, password): Promise<UserCredential> {
    return this._afa.auth.createUserWithEmailAndPassword(email, password);
  }

  logout() {
    this._afa.auth.signOut();
    this.router.navigate(['/']);
  }

  public isLoggedIn() {
    return this.actualUser != null;
  }

  private updateUserData() {
    let self = this;
    this._afa
      .authState
      .subscribe((user: User) => {
        if (user) {
          this.actualUser = user;
          this.userId = user.uid;
          // Put this in the call stack for better performance i guess
          new Promise((resolve) => {
            self._db.collection('users',
              ref => ref.where('id', '==', this.userId)
            )
              .valueChanges()
              .pipe(first())
              .subscribe((res) => {
                resolve(res);
              });
          })
            .then((data: any) => {
              self.userData = data[0];
              this.userDataEmitter.next(data[0]);
              console.log('Updated user data', data[0]);
            })
            .catch((err) => {
              console.error(err);
            })
          ;
        } else {
          self.actualUser = null;
        }
      });
  }

  deleteUserAccById(uid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = new HttpParams().set('uid', uid);
      this.http.delete('https://us-central1-recyclenow-f43c3.cloudfunctions.net/api/deleteAccount', {params})
        .pipe(first())
        .subscribe(r => {
          resolve(r);
        }, err => {
          reject(err);
        });
    });
  }

}

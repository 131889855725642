import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {AuthGuard} from './authentication/auth.guard';
import {AuthService} from './authentication/auth.service';
import {AngularFirestoreModule} from '@angular/fire/firestore';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    AngularFirestoreModule
  ],
  declarations: [],
  providers: [
    AuthGuard,
    AuthService
  ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }

}

import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {first} from 'rxjs/operators';
import {User} from 'firebase';

@Injectable()
export class AuthGuard implements CanActivate {


  constructor(private _auth: AuthService, private _router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {
    return new Promise((resolve) => {
      this._auth
        .authState
        .pipe(first())
        .subscribe(async (user: User) => {
          resolve(user != null);
        });
    });

  }

}

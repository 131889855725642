import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {

  @Input() visible: boolean;
  @Input() content: string;
  @Input() color: string;

  public openToast = () => this.visible = true;
  public closeToast = () => this.visible = false;

}

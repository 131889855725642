export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDt-ytPFSr9Q5xZBJEyWUZxvpIw7r8S96M',
    authDomain: 'recyclenow-f43c3.firebaseapp.com',
    databaseURL: 'https://recyclenow-f43c3.firebaseio.com',
    projectId: 'recyclenow-f43c3',
    storageBucket: 'recyclenow-f43c3.appspot.com',
    messagingSenderId: '172654289992'
  }
};
